import React from "react"

const RedirectPage = () => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          display: "flex",
          zIndex: 10000,
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <div style={{ fontSize: 50, fontWeight: 500 }}>
            Choose a shopping region.
          </div>
          <div className="d-flex justify-content-center">
            <div>
              <a href="https://vi.paradise4saigon.com/shop">
                <div className="shop-location">Vietnam</div>
              </a>
            </div>
            <a href="https://www.paradise4saigon.com/shop">
              <div className="shop-location">Worldwide</div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default RedirectPage
